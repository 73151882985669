
@import "~noty/src/noty.scss";
@import "~noty/src/themes/sunset.scss";

@import "./overrides.scss";

.ios.platform-mobile ion-menu.menu-enabled, .ios.platform-mobile ion-menu.menu-enabled, .ios.platform-mobile ion-modal.show-page {
    padding-top: constant(safe-area-inset-top); 
    padding-top: env(safe-area-inset-top); 
    margin-bottom: env(safe-area-inset-bottom);
    height: calc(100% - constant(safe-area-inset-top));
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}