// PEGA Injected CSS Overrides
INPUT[type="text"], INPUT[type="email"], INPUT[type="url"], INPUT[type="number"], INPUT[type="tel"], INPUT[type="password"] {
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: normal;
    text-decoration: inherit;
    text-transform: none;
    padding:7px 0 !important;
    height: 19px !important;
    background-image: none;
    min-height: auto !important;
    border-radius: 0 !important;
    border:none !important;
    line-height: 20px !important;
    background-color: transparent !important;
  }
  
